import clsx from 'clsx'
import React from 'react'
import './CherieLink.scss'

function CherieLink(props) {
  const {
    text = 'Link'
  } = props
  return (
    <div className={clsx('CherieLink')}>
      <span>{text}</span>
    </div>
  )
}

export default CherieLink
