import clsx from 'clsx'
import React from 'react'
import {Text} from '../../functions'
import './CherieText.scss'

export default function CherieText(props) {
  const {simple, text} = props
  return (
    <div className={clsx('CherieText', simple && 'simple')}>
    <Text>{text}</Text>
    </div>
  )
}
