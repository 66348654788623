import React from 'react'
import { Link } from 'react-router-dom'
import Button from '../../components/CherieButton'
import LoginButton, {SimpleButton} from '../../components/LoginButton'
import { LinkedIn } from 'react-linkedin-login-oauth2'
import AppleSignin from 'react-apple-signin-auth'
import './Login.scss'

export default function Login() {
  const [mail, setMail] = React.useState('')
  const [password, setPassword] = React.useState('')
  const don = d => {
    console.log("success")
    console.log(d)
  }
  const don2 = d => {
    console.log("error")
    console.log(d)
  }
  return (
    <div className='Login'>
      <Link to="/signup" className="l-connex-button">créer un compte</Link>
      <div className="l-buttons simple">
        <LoginButton
          name="facebook"
          icon='facebook-f'
          provider='facebook'
          appId='131629908481878'
          onLoginSuccess={don}
          onLoginFailure={don2}
          base="Se connecter"
        />
        {/*  */}
        <LinkedIn
          clientId="77guldina1f45d"
          onFailure={don}
          onSuccess={don2}
          redirectUri="http://localhost:3000"
          renderElement={({ onClick, disabled }) => (
            <SimpleButton
              name="linkedin"
              icon='linkedin-in'
              base="Se connecter"
              provider='linkedin'
              triggerLogin={onClick}
              disabled={disabled}
            />
          )}
        />
        <AppleSignin
          authOptions={{
            clientId: 'com.example.web',
            scope: 'email name',
            redirectURI: 'https://example.com',
            state: 'state',
            nonce: 'nonce',
            usePopup: true,
          }}
          uiType="dark"
          onSuccess={don}
          onError={don2}
          skipScript={false}
          render={({ onClick, ...props }) => <SimpleButton base="Se connecter" triggerLogin={onClick} name="apple" />}
        />
        <SimpleButton
          base="Se connecter"
          simple
          name="google"
          provider='linkedin'
        />
      </div>
      <div className="l-separator" />
      <div className="l-buttons">
        <input  placeholder='Email' type="email"/>
        <input placeholder='Mot de passe' type="password"/>
        <Button simple inverse text={"Se connecter"} />
      </div>
    </div>
  )
}
