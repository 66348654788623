import clsx from 'clsx'
import React from 'react'
import './Coaches.scss'
import exim from '../../assets/images/home/photo_4.png'
import Button from '../CherieButton'
import {icons, images} from '../../assets/images'
export default function Coaches(props) {
  const {
    title = _title,
    detail = _detail,
    images = [exim, exim, exim]
  } = props
  return (
    <div className={clsx('Coaches')}>
      <div className="cc-title">{title}</div>
      <div className="cc-details">{detail}</div>
      <div className="cc-coaches">
        {
          images.map((url, i) => {
            return <div
            key={i}
              style={{backgroundImage: `url(${url})`}} 
              className="cc-profile"
            />
          })
        }
        <div className="cc-profile">
          <div className="cc-container">
            <span className='cc-discover-icon'></span>
            <span className='cc-discover-text'>
              Découvrir les coaches
            </span>
          </div>
        </div>
      </div>
      <Button text='Voir les vidéos de Marie' icon={icons.video}/>
    </div>
  )
}

const _title = "Cherrissez vous ! Marie et ses professionnels et coachs prennent soin de vous"
const _detail = "A short, but complete sentence that takes up first and second lines"