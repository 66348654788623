import React from 'react'
import clsx from 'clsx'
import Chooser from '../../components/Chooser'
import {useAppContext} from '../../provider/context'

export default function StepFour({ className, stepper, ...rest }) {
  const { selector, setRequired } = stepper
  // const fields = ['interests', 'interests2']
  const fields = ['activity', 'sport']
  setRequired(fields)

  //Data to show
  const { activity = [], sports = [] } = useAppContext().signupFields || {}
  const interestsOptions = activity.filter(({is_active}) => is_active).map(({name: label, id: value}) => ({label, value}))
  const sportOptions = sports.filter(({is_active}) => is_active).map(({name: label, id: value}) => ({label, value}))

  return (
    <fieldset {...rest} className={clsx('step stepfour', className)}>
      <div className="sgp-title spaced">Mes Activités</div>
      <div className="sgp-title sub">Mes centres d’intérêt :</div>
      <Chooser
        options={interestsOptions}
        {...selector(fields[0])}
        multiple
      />
      <div className="sgp-title sub">Mes activités sportives :</div>
      <Chooser
        options={sportOptions}
        {...selector(fields[1])}
        multiple
      />
    </fieldset>
  )
}


const interestsOptions = [
  'Cinéma', 'Bien-être', 'Art',
  'Lecture', 'Théâtre', 'Concert',
  'Mode', 'Oénologie', 'Beauté',
  'Gastronomie', 'Photographie'
].map((label, value) => ({ label, value }))

const sportOptions = [
  'Football', 'Natation', 'Golf',
  'Yoga', 'Fitness', 'Equitation',
  'Rugby', 'Tennis', 'Voile',
  'Fumming', 'Danse'
].map((label, value) => ({ label, value }))