import clsx from 'clsx'
import React, { useRef, useState, useEffect } from 'react'
import PhotoBox from './PhotoBox'
import './PhotoGrid.scss'
import Webcam from "react-webcam"

const stopEvent = e => {
  e.stopPropagation()
  e.preventDefault()
}

export default function PhotoGrid(props) {
  const {onChange, name} = props
  const inpref = useRef(null)
  const [images, setImages] = useState([])
  const [imageToChange, setImageToChange] = useState(null)
  const [webcam, setWebcam] = useState(false)
  const [shooting, setShooting] = useState(false)
  useEffect(() => {
    onChange({name, images})
  }, [images])
  const removeInp = () => {
    inpref.current && document.querySelector('body').removeChild(inpref.current)
    inpref.current = null
  }
  const webcamRef = React.useRef(null)
  const onImageClicked = (image2change) => {
    setImageToChange(image2change)
    setWebcam(true)
  }
  const importFile = () => {
    removeInp()
    const inp = document.createElement('input')
    document.querySelector('body').appendChild(inp)
    inp.type = 'file'
    // inp.multiple = true
    inp.accept = 'image/*'
    inp.style.cssText = 'width: 0; opacity: 0; height: 0; position: fixed'
    inp.addEventListener('change', _ => {
      const files = Object.getOwnPropertyNames(inp.files).map(i => {
        const file = inp.files[i]
        file.url = URL.createObjectURL(file)
        return file
      })
      const image = files[0]
      if (imageToChange) {
        setImages(images.map(img => img === imageToChange ? image : img))
        setImageToChange(null)
      }
      else setImages([...images, ...files].slice(-3))
      removeInp()
      setShooting(false)
      setWebcam(false)
    }, false)
    inpref.current = inp
    inp.click()
  }
  const shoot = () => {
    if (!webcam) {
      setWebcam(true)
    }
    else if (shooting) {
      const img = webcamRef.current.getScreenshot()
      fetch(img)
        .then(res => res.arrayBuffer())
        .then(buf => {
          const file = new File([buf], 'taken.jpg', { type: "image/jpeg" })
          file.url = URL.createObjectURL(file)
          return file
        })
        .then(file => {
          if(imageToChange){
            setImages(images.map(img => {
              return img === imageToChange ? file : img
            }))
            setImageToChange(null)
          }
          else {
            setImages([...images, file].slice(-3))
          }
          setShooting(false)
          setWebcam(false)
        })
    }

  }
  const [img0, img1, img2] = images
  return (
    <div className='PhotoGrid'>
      <PhotoBox
        image={img2}
        onClick={_ => img2 && onImageClicked(img2)}
      >
        <div
          className={clsx("shoot", shooting && 'ready')}
          onClick={e => {
            stopEvent(e)
            shoot()
          }}
        />
        <div
          className={clsx("close-shoot", shooting && 'active')}
          onClick={e => {
            stopEvent(e)
            setShooting(false)
            setWebcam(false)
          }}
        />
        {
          webcam && <div className="cam-parent">
            <Webcam
              ref={webcamRef}
              audio={false}
              screenshotFormat="image/jpeg"
              onUserMedia={_ => setShooting(true)}
            />
          </div>
        }
        <div
          onClick={e => {
            stopEvent(e)
            importFile()
          }}
          className={clsx("import", shooting && 'active')}
        />
      </PhotoBox>
      <PhotoBox image={img1} onClick={_ => img1 && onImageClicked(img1)} />
      <PhotoBox image={img0} onClick={_ => img0 && onImageClicked(img0)} />
    </div>
  )
}
