import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Store from './store'

ReactDOM.render(
  <Store.StoreProvider>
    {/* <React.StrictMode> */}
    <App />
    {/* </React.StrictMode> */}
  </Store.StoreProvider >
  ,
  document.getElementById('root')
);

reportWebVitals();
