import clsx from 'clsx'
import React from 'react'
import './EventTicket.scss'
import { details, titles, dates } from '../../functions/template'
import { Text } from '../../functions'
import Button from '../CherieButton'
import QRCode from "react-qr-code";

export default function EventTicket(props) {
  const {
    title = titles[0],
    detail = details[0],
    date = dates[0],
    modify,
    text,
    cancel
  } = props
  return (
    <div className={clsx('EventTicket')}>
      <div className="et-box">
        <div className="et-code-title">
          Le code barre d’accès<br/> à l’événement
        </div>
        <div className="et-qr">
          <QRCode size={150} value={text} />
        </div>

        <div className="et-separator"/>
        <Button small inverse text='Vous êtes inscrit à cet évènement'/>
        <div className="et-detail">
          Vous avez invité 2 amis, avec une table à 21h30
        </div>
        <div className="et-options">
          <Button onClick={cancel} inverse text='Annuler'/>
          <Button onClick={modify} text='Modifier'/>
        </div>
      </div>
    </div>
  )
}
