import React from 'react'
import clsx from 'clsx'
import CherieButton from '../../components/CherieButton'

const texts = [
  `Me décrire en quelques mots (pas de crainte, personne n'est là pour te juger)`,
  "Raconte moi un maximum de choses sur toi ! ",
  "JE VALIDE LES CGU ET LA POLITIQUE CONFIDENTIALITE COLLECTE ET TRAITEMENT DES DONNEES"
]

export default function StepNine({ className, stepper, ...rest }) {
  const { setRequired, attr } = stepper

  //hendler on next
  const {setNextHandler, handlers} = stepper
  setNextHandler(handlers.sendData)

  const fields = ['about_me', 'accept_policy.checked']
  const F = attr(fields)
  setRequired(fields)

  return (
    <fieldset {...rest} className={clsx('step stepnine', className)}>
      <div className="sgp-title spaced">Cheessse!!</div>
      <div className="sgp-title sub">{texts[0]}</div>
      <div className="sgp-text-parent">
        <textarea {...F[0]} className="form-control" id="moi" placeholder={texts[1]} />
      </div>
      <label className="checkbox">
        <input {...F[1]} type="checkbox" />
        <span className="checkmark"></span>
        <span>{texts[2]}</span>
      </label>
      {/* <CherieButton>Je valide</CherieButton> */}
    </fieldset>
  )
}
