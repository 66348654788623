/** ======================================================================
//
// ACTIONS TYPES
//
====================================================================== **/
export const GET_ALL_EVENT = "GET_ALL_EVENT";
export const SIGNIN = "SIGNIN";
export const UNREAD_MESSAGE = "UNREAD_MESSAGE";
export const READ_MESSAGE = "READ_MESSAGE";
export const SET_CURRENT_EVENT = "SET_CURRENT_EVENT";
