import React from 'react'
import clsx from 'clsx';
import './Cherie.scss'

export default function Cherie(props) {
  const {name, connected, premium, image, index,} = props
  return (
    <div className={clsx('LesCheries', connected && 'connected', premium && 'premium')}>
      <div className="image" style={{backgroundImage: `url(https://picsum.photos/id/${40 + index}/200/300)`}}/>
      <div className="name">{name}</div>
    </div>
  )
}