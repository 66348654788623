import React, { useEffect } from 'react'
import './BigCheckbox.scss'
import clsx from 'clsx'

export default function BigCheckbox(props) {
  React.useEffect(() => {
    onChange && onChange({value: false})
  }, [])
  const {value = false, label, onChange, simple, ...rest} = props
  return (
    <div onClick={_ => onChange({value: !value})} className={clsx('BigCheckbox', simple && "simple")} {...rest}>
      {label && <div className='label'>{label}</div>}
      <div className={clsx('checkbox', value && 'checked')}/>
    </div>
  )
}
