import React from 'react'
import clsx from 'clsx'
import Chooser from '../../components/Chooser'
import {useAppContext} from '../../provider/context'

export default function StepFive({ className, stepper, ...rest }) {
  const { selector, setRequired } = stepper
  const fields = ['sex', 'meet']
  setRequired(fields)

  //Data to show
  const { sex = [], me_search = [] } = useAppContext().signupFields || {}
  const sexOptions = sex.filter(({is_active}) => is_active).map(({sex: label, id: value}) => ({label, value}))
  const meetOptions = me_search.filter(({is_active}) => is_active).map(({type_search: label, id: value}) => ({label, value}))

  return (
    <fieldset {...rest} className={clsx('step stepfive', className)}>
      <div className="sgp-title spaced">En savoir plus</div>
      <div className="sgp-title sub">Je suis :</div>
      <Chooser
        big
        spaced
        options={sexOptions}
        {...selector(fields[0])}
      />
      <div className="sgp-title sub spaced">Je souhaite rencontrer</div>
      <Chooser
        spaced
        options={meetOptions}
        {...selector(fields[1])}
        full
      />
    </fieldset>
  )
}


const sexOptions = ['Un homme', 'Une femme'].map((label, value) => ({ label, value }))
const meetOptions = ['Un homme', 'Une femme', 'Sans préférence'].map((label, value) => ({ label, value }))