import clsx from 'clsx'
import React from 'react'
import SocialLogin from 'react-social-login'
import './LoginButton.scss'
import {icons} from '../../assets/images'

const capitalize = str => str.charAt(0).toUpperCase() + str.slice(1)

function LoginButton(props) {
  const {name, icon=name, base='Continuer', simple, triggerLogin, ...rest} = props
  const params = {
    ...rest,
    onClick: triggerLogin
  }
  return (
    <div {...params} className={clsx('LoginButton', name, simple && "simple")}>
      <div style={{backgroundImage: `url(${icons[name]})`}} className="icon"/>
      { props.children }
      <span>{base} avec {capitalize(name)}</span>
    </div>
  );
}

export const SimpleButton = LoginButton

export default SocialLogin(LoginButton);