import React from 'react'
import LoginButton, {SimpleButton} from '../../../components/LoginButton'
import { LinkedIn } from 'react-linkedin-login-oauth2'
import AppleSignin from 'react-apple-signin-auth';
import {Text} from '../../../functions/index'
import Button from '../../../components/CherieButton'
import SimpleSignup from './SimpleSignup'

import './MainSignup.scss'
import { Link } from 'react-router-dom';
import clsx from 'clsx';

function MainSignup(props) {
  const { className, stepper, ...rest } = props
  const {setRequired, next, addToForm} = stepper
  setRequired(['invisible'])
  const [simple, setSimple] = React.useState(false)
  const don = data => {
    console.log(data)
    //next()
  }
  const don2 = d => {
  }

  return (
    <div {...rest} className={clsx("MainSignup simple", className)}>
      <SimpleSignup stepper={stepper}  next={next} back={() => setSimple(false)} active={simple}/>
      <Link to="/login" className="ms-connex-button">connexion</Link>
      <div className="ms-title">
        <Text>{"Bienvenue\nparmi les\nCheri(e)s"}</Text>
      </div>
      <div className="ms-buttons">
        <LoginButton
          name="facebook"
          icon='facebook-f'
          provider='facebook'
          appId='839838456773659' //'131629908481878'
          onLoginSuccess={({_profile: {firstName, lastName, id}, _token: {accessToken}}) => {
            addToForm({name: 'firstname', value: firstName})
            addToForm({name: 'lastname', value: lastName})
            addToForm({name: 'token', value: accessToken})
            addToForm({name: 'id_facebook', value: id})
            next()
          }}
        />
        {/*  */}
        <LinkedIn
          clientId="77guldina1f45d"
          onFailure={don}
          onSuccess={don2}
          redirectUri="http://localhost:3000"
          renderElement={({ onClick, disabled }) => (
            <SimpleButton
              name="linkedin"
              icon='linkedin-in'
              provider='linkedin'
              triggerLogin={onClick}
              disabled={disabled}
              style={{opacity: .5}}
            />
          )}
        />
        <AppleSignin
          authOptions={{
            clientId: 'com.example.web',
            scope: 'email name',
            redirectURI: 'https://example.com',
            state: 'state',
            nonce: 'nonce',
            usePopup: true,
          }}
          uiType="dark"
          onSuccess={don}
          onError={don2}
          skipScript={false}
          render={({onClick, ...props}) => <SimpleButton style={{opacity: .5}} triggerLogin={onClick} name="apple"/>}
        />
        <SimpleButton
          name="google"
          provider='linkedin'
          style={{opacity: .5}}
        />
        <Button onClick={() => setSimple(true)} text={'Ou avec notre compte'}/>
      </div>
      <div className='ms-footer'>
        <Text>{`Nous ne publions rien sur ta page facebook ou linkedin.\nEn continuant tu acceptes nos conditions de services et notre politiques de confidentialité`}</Text>
      </div>
    </div>
  );
}

export default MainSignup;