import React, { useState } from 'react'
import Stepper from '../../components/Stepper'
import { AppContext } from '../../provider/context'
import {connect} from '../../store'
import {
  StepOne, StepTwo, StepThree,
  StepFour, StepFive, StepSix,
  StepSeven, StepEight, StepNine,
  MainSignup
} from './steps'
import CurtailOne from './CurtailOne'
import Charte from './Charte'
import "./Signup.scss"
import { getCompleteProfil as getUploadFields } from 'common/src/backend/UserbackEndService'

function SignUp(props) {
  const {signup, history} = props
  React.useEffect(() => {
    async function getData() {
      let data = await getUploadFields()
      setSignupFields(data)
    }
    getData()
  }, [])
  const [signupFields, setSignupFields] = React.useState()
  const [form, setForm] = useState({
    // email: "marson@gmail.com",
    // password: 'hohoho',
    // about_me: "Yaloo",
    // accept_policy: true,
    // activity: [2],
    // adress: "Lieu",
    // cause: [4, 5],
    // code_parain: "PPPP",
    // date_of_birth: "2020-12-08",
    // firstname: "Prenom",
    // lastname: "NOm",
    // make_new_friend: false,
    // meet: [2],
    // sex: [2],
    // trip: [4],
    // sport: [1]
  })
  const addToForm = (e, valType = 'value') => {
    const source = e.target || e
    form[source.name] = source[valType]
    setForm({
      ...form,
      [source.name]: source[valType]
    })
  }
  const sendData = () => {
    signup(form).then(() => {
      setForm({...form, finished: true})
    })
  }
  const goToNextRoute = () => {
    history.push('/')
  }
  return (
    <AppContext.Provider
      value={{signupFields}}
    >
      <Stepper handlers={{ navigate: goToNextRoute, sendData }} {...{ addToForm, form }} className='SignUp'>
        <MainSignup/>
        <StepOne />
        <CurtailOne />
        <StepTwo />
        <StepEight />
        <StepThree />
        <StepSix />
        <StepFour />
        <StepFive />
        <StepSeven />
        <StepNine />
        <Charte />
      </Stepper>
    </AppContext.Provider>
  )
}

export default connect(SignUp)

/**
 *
    email: "marson@gmail.com",
    password: 'hohoho',
    about_me: "Yaloo",
    accept_policy: true,
    activity: [2],
    adress: "Lieu",
    cause: [4, 5],
    code_parain: "PPPP",
    date_of_birth: "2020-12-08",
    firstname: "Prenom",
    lastname: "NOm",
    make_new_friend: false,
    meet: [2],
    sex: [2],
    trip: [4],
    sport: [1]

 */
