import { createStore, combineReducers, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import { persistStore, persistReducer } from "redux-persist";
import authReducer from "./reducer/auth";
import eventReducer from "./reducer/event";
import default_storage from "redux-persist/lib/storage";
import { PersistGate } from "redux-persist/integration/react";
import thunk from "redux-thunk";

export default (storage = default_storage) => {
  const store = createStore(
    combineReducers({
      auth: persistReducer(
        { key: "auth", storage, blacklist: [] },
        authReducer
      ),
      event: eventReducer,
    }),
    applyMiddleware(thunk)
  );

  const persistor = persistStore(store);
  const getCurrentToken = ($) => {
    return store.getState().auth.token;
  };

  const StoreProvider = function Store({ children, loading: Loader }) {
    return (
      <Provider Provider store={store}>
        <PersistGate persistor={persistor} loading={Loader ? <Loader /> : null}>
          {children}
        </PersistGate>
      </Provider>
    );
  };

  const getCurrentState = (item) => {
    const state = store.getState();
    return item ? item.split(".").reduce((obj, key) => obj[key], state) : state;
  };
  return { getCurrentState, getCurrentToken, StoreProvider };
};
