import React from 'react'
import './GalleryPart.scss'

export default function GalleryPart(props) {
  const {height = 220, index} = props
  const gap = height / 50
  const style = {
    width: height * 2 + gap * 3,
    height: height + gap,
    gap,
    marginRight: gap
  }
  return (
    <div style={style} className="GalleryPart">
      <div style={{backgroundImage: `url(https://picsum.photos/id/${11 + 5 * index}/200/300)`}} className="gp-image"/>
      <div style={{backgroundImage: `url(https://picsum.photos/id/${12 + 5 * index}/200/300)`}} className="gp-image"/>
      <div style={{backgroundImage: `url(https://picsum.photos/id/${13 + 5 * index}/200/300)`}} className="gp-image"/>
      <div style={{backgroundImage: `url(https://picsum.photos/id/${14 + 5 * index}/200/300)`}} className="gp-image"/>
      <div style={{backgroundImage: `url(https://picsum.photos/id/${15 + 5 * index}/200/300)`}} className="gp-image"/>
    </div>
  )
}
