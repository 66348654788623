import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import Button from '../../../components/CherieButton'
import {Text} from '../../../functions/index'
import './SimpleSignup.scss'

function Login(props) {
  const {active, back, next, stepper} = props
  const {attr, form:{password}, addToForm} = stepper
  const fields = ['email', 'password']
  const [error, setError] = useState(false)
  const [validator, setValidator] = useState("")
  const F = attr(fields)
  useEffect(() => {
    setError(false)
  }, [password, validator])
  const checkNext = () => {
    if(password && password === validator) next()
    else setError(true)
  }
  const goBack = () => {
    addToForm({name: fields[0], value: undefined})
    addToForm({name: fields[1], value: undefined})
    back()
  }
  return (
    <div className={clsx("SimpleSignup", active && 'active')}>
      <span onClick={goBack} className="ss-connex-button">retour</span>
      <div className="ss-title">
        <Text>{"Inscription"}</Text>
      </div>
      <div className="ss-separator"/>
      <div className="ss-buttons">
        <input
          {...F[0]}
          placeholder='Email'
          type="email"
        />
        <input
          className={clsx(error && 'error')}
          {...F[1]}
          placeholder='Mot de passe'
          type="password"
        />
        <input
          className={clsx(error && 'error')}
          onChange={e => setValidator(e.target.value)}
          value={validator}
          placeholder='Confirmer le mot de passe'
          type="password"
        />
      </div>
      <div className="ss-buttons">
        <Button onClick={checkNext} inverse text={"S'inscrire"}/>
      </div>
    </div>
  );
}

export default Login;