import { URL } from "../constant/endpoint";
import Axios from "../api";
export const getCompleteProfil = async () => {
  let response = await Axios.get({
    auth: false,
    url: URL.auth_route.get_profil_account,
  });
  return response ? response.data : response;
};

export const signup = async (data) => {
  let response = await Axios.post({
    auth: false,
    url: URL.auth_route.valide_info_user,
    data: Axios.toFormData(data),
  });
  console.log("responseresponse", response);
  return response ? response.data : response;
};

export const signin = async (data) => {
  let response = Axios.post({
    auth: false,
    url: URL.auth_route.signin,
    data: Axios.toFormData(data),
  });
  return response ? response.data : response;
};
