import clock from './icons/heure.svg'
import pin from './icons/address.svg'
import article from './icons/article.svg'
import apple from './icons/apple.svg'
import facebook from './icons/facebook.svg'
import linkedin from './icons/linkedin.svg'
import google from './icons/google.svg'
import starsmile from './icons/starsmile.svg'
import video from './icons/video.svg'
import arrow from './icons/arrow.svg'
import girltransparent from './images/girltransparent.png'
import doubleprofil from './icons/doubleprofil.png'
import glass from './icons/glass.svg'
import gift from './icons/gift.svg'
import infinite from './icons/infinite.svg'
import voile from './images/voile.png'
import heartcare from './icons/heartcare.svg'
import shieldok from './icons/shieldok.svg'
import heartok from './icons/heartok.svg'

export const icons = {
  clock, pin, article, google,
  apple, facebook, linkedin,
  starsmile, video, arrow,
  doubleprofil, gift, glass,
  infinite, heartok, shieldok,
  heartcare
}

export const images = {
  girltransparent, voile
}