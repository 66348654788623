import { Fragment } from "react"

export const stopEvent = e => {
  e.stopPropagation()
  e.preventDefault()
}

export const Text = ({ children }) => {
  const all = (children || '').split('\n')
  return <>
    {
      all.map((text, i) => {
        return <Fragment key={i}>{text}{<br/>}</Fragment>
      })
    }
  </>
}