import React from 'react'
import Accueil from './containers/Home'
import Event from './containers/Event'
import Login from './containers/Login'
import SignUp from './containers/SignUp'
import { Route, Switch } from 'react-router-dom'
import SimpleSignup from './containers/SignUp/MainSignup/SimpleSignup'
import Test from './containers/SignUp/Charte'
import EventValidate from './components/EventValidate'

export default function Router(props) {
  return (
    <Switch>
      {
        [...protectedRoutes, ...routes].map(({ path, component }, key) => <Route exact={path === '/'} {...{ key, component, path }} />)
      }
    </Switch>
  )
}

export const getFromRoute = (route) => {
  return [...protectedRoutes, ...routes].find(r => r.path === route) || {}
}

const protectedRoutes = [
  ['/', Accueil, true, true],
  ['/event', Event, true, true],
].map(([path, component, header, footer]) => ({ path, component, header, footer, isProtected: true }))

const routes = [
  ['/simplesignup', SimpleSignup],
  ['/login', Login],
  ['/test', Test],
  ['/signup', SignUp],
  ['/event', EventValidate]
].map(([path, component, header, footer]) => ({ path, component, header, footer }))
