import React from 'react'
import clsx from 'clsx'
import './PhotoBox.scss'

export default function PhotoBox(props) {
  const {image, webcam, children, onShoot, ...rest} = props
  const style = image ? {backgroundImage: `url(${image.url || URL.createObjectURL(image)})`} : null
  
  return (
    <div style={style} className={clsx('PhotoBox', image && 'filled')} {...rest}>
      {children}
    </div>
  )
}