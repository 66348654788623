import clsx from 'clsx'
import React from 'react'
import { Text } from "../../functions"
import './Plaque.scss'

export default function Plaque(props) {
  const { image, text } = props
  return (
    <div className={clsx('Plaque')}>
      { image && <div style={{ backgroundImage: `url(${image})` }} className="p-logo" />}
      <div className="p-text">
        <Text>{text}</Text>
      </div>
    </div>
  )
}
