import { SIGNIN } from "../../../constant/redux.constant";

const initialState = {
  user: {},
  token: null
}
export default (state = initialState, action) => {
  switch (action.type) {
    case SIGNIN:
      return {
        ...state,
        user: action.data.user,
        token: action.data.accessToken
      }
    default:
      return state;
  }
};
