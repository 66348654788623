import React, { useEffect } from 'react'
import clsx from 'clsx'
import HomePart from '../../components/HomePart'
import EventList from '../../components/EventList'
import GallerySlider from '../../components/GallerySlider'
import CherieSlider from '../../components/CherieSlider'
import InfoBack from '../../components/InfoBack'
import Coaches from '../../components/Coaches'
import Ecology from '../../components/Ecology'
import {connect} from '../../store'

function Home(props) {
  const {getEvent, event} = props
  useEffect(() => {
    getEvent()
  }, [])

  return (
    <div className={clsx('Home')}>
      <HomePart
        title={'Les Événements Chérie Chéri'}
        // link={'Tous les Événements Chérie Chéri'}
      >
        <EventList events={event.events}/>
      </HomePart>
      <HomePart
        title={'Ça ne se raconte pas, ça se vit !'}
        link={'Toutes les galeries'}
      >
        <GallerySlider/>
      </HomePart>
      <HomePart
        title={'Echanger avec les Chéri(e)s'}
        link={'Tous et toutes les Chéri(e)s'}
      >
        <CherieSlider/>
      </HomePart>
      <HomePart>
        <InfoBack
          side
          title={'Montrer votre intérêt'}
          detail={'Offrez lui un cadeau qui a du sens'}
        />
      </HomePart>
      <HomePart>
        <Coaches/>
      </HomePart>
      <HomePart>
        <Ecology/>
      </HomePart>
    </div>
  )
}

export default connect(Home)