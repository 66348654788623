import clsx from 'clsx'
import React from 'react'
import './EventCard.scss'
import People from '../People'
import { details, titles, dates } from '../../functions/template'
import { Text } from '../../functions'
import PinDetail from '../PinDetail'
import {icons} from '../../assets/images'
import {formatDate} from 'common/functions'

export default function EventCard(props) {
  const {
    title = titles[0],
    detail = details[0],
    date = dates[0],
    name,
    description,
    date_start,
    id
  } = props
  return (
    <div className={clsx('EventCard')}>
      <div  style={{backgroundImage:`url(https://picsum.photos/id/${id}/200/300)`}} className="ec-image">
        <People />
      </div>
      <div className="ec-info">
        <div className="ec-date">{formatDate(new Date(date_start))}</div>
        <div className="ec-title">
          <Text>{name}</Text>
        </div>
        <div className="ec-detail">
          <Text>{description}</Text>
        </div>
        <div className="ec-more">
          <PinDetail
            icon={icons.pin}
            text={"La péniche Chérie Cheri\n14 Rue Monsieur le Prince\n75006 Paris"}
          />
          <PinDetail
            icon={icons.clock}
            text={"A partir de\n19h30"}
          />
        </div>
      </div>
    </div>
  )
}
