import React from 'react'
import clsx from 'clsx'
import Chooser from '../../components/Chooser'
import BigCheckbox from '../../components/BigCheckbox'
import {useAppContext} from '../../provider/context'

export default function StepThree({ className, stepper, ...rest }) {
  const {setRequired, selector, attr} = stepper
  const fields = ['meet','want_expand_pro_circle','make_new_friend']
  setRequired([fields[0]])
  const F = attr([fields[1], fields[2]], false)

  //Data to show
  const { search_love = [] } = useAppContext().signupFields || {}
  const relationOptions = search_love.filter(({is_active}) => is_active).map(({type_search: label, id: value}) => ({label, value}))

  return (
    <fieldset {...rest} className={clsx('step stepthree', className)}>
      <div className="sgp-title spaced">Je recherche</div>
      <div className="sgp-title sub">
        Une photo de profil c'est mieux pour être un ou une parfait(e) Chéri(e)
      </div>
      <Chooser
        options={relationOptions}
        full
        spaced
        {...selector(fields[0])}
      />
      <BigCheckbox
        label={'Elargir mon cercle professionel'}
        {...F[0]}
      />
      <BigCheckbox
        label={'Se faire de nouvelles relations amicales'}
        {...F[1]}
      />
    </fieldset>
  )
}
