import clsx from 'clsx'
import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.scss'
import EventBox from '../EventBox'
import { connect } from '../../store'

import { useHistory } from 'react-router-dom';

function EventList(props) {
  const history = useHistory()
  const { events, setCurrentEvent } = props
  return (
    <div className={clsx('EventList')}>
      <Swiper
        freeMode={true}
        slidesPerView="auto"
        mousewheel={{
          forceToAxis: true
        }}
        direction="horizontal"
        slidesPerView="auto"
        spaceBetween={15}
        // onSlideChange={() => }
        onSwiper={(swiper) => setTimeout(() => {
          swiper.update()
        }, 200)}
        style={{ padding: 3, overflow: 'auto' }}
        slidesOffsetAfter={25}
      >
        {
          events.map((event, key) => {
            return <SwiperSlide key={key} style={{ width: "auto" }}>
              <EventBox
                clicked={() => {
                  setCurrentEvent(event.id).then(_ => {
                    history.push('/event')
                  })
                }}
                {...event} />
            </SwiperSlide>
          })
        }
      </Swiper>
    </div>
  )
}

export default connect(EventList)