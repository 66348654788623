import React from 'react'
import LogoBox from '../LogoBox'
import './ChooserLogoBox.scss'

export default function ChooserLogoBox(props) {
  const { multiple, options = [], selected = [], setSelected, column = 2 } = props
  const addSelected = val => {
    if (selected.includes(val)) {
      setSelected && setSelected(selected.filter(v => v !== val))
    }
    else {
      setSelected && setSelected(multiple ? [...selected, val] : [val])
    }
  }

  return (
    <div className="ChooserLogoBox">
      {
        options.map(({ value, ...rest}, i) => {
          return <LogoBox
            style={{width: 100/column + '%'}}
            key={i}
            {...rest}
            checked={selected.includes(value)}
            setChecked = {_ => addSelected(value)}
          />
        })
      }
    </div>
  )
}