import React from 'react'
import clsx from 'clsx'
import Chooser from '../../components/Chooser'
import {useAppContext} from '../../provider/context'

export default function StepSix({ className, stepper, ...rest }) {
  const {setRequired, selector} = stepper
  const fields = ['trip']
  setRequired(fields)

  //Data to show
  const { trip = [] } = useAppContext().signupFields || {}
  const vacationOptions = trip.filter(({is_active}) => is_active).map(({name: label, id: value}) => ({label, value}))
  return (
    <fieldset {...rest} className={clsx('step stepsix', className)}>
      <div className="sgp-title spaced">Quoi d'autre ?</div>
      <div className="sgp-title sub">Mes Vacances:</div>
      <Chooser
        options={vacationOptions}
        {...selector(fields[0])}
      />
    </fieldset>
  )
}
