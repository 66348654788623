import React from 'react';
import clsx  from 'clsx'
import People from '../People'
import {formatDate} from 'common/functions'
import { Text } from '../../functions'
import Button from '../CherieButton'
import './EventBox.scss'


export default function EventBox(props){
  const {
    createdAt,
    date_creation,
    date_end,
    date_start,
    description,
    id,
    image_filename,
    is_active,
    is_finished,
    name,
    place_id,
    place_number,
    price,
    table_number,
    updatedAt,
    clicked
  } = props
    return(
      <div style={{backgroundImage:`url(https://picsum.photos/id/${id}/200/300)`}} className={clsx('EventBox')}>
        <div className="le-date">{formatDate(new Date(date_start))}</div>
        <People/>
        <div className="le-title">
          <Text>{name}</Text>
        </div>
        <div className="le-detail">
          <Text>{description}</Text>
        </div>
        <Button onClick={clicked} text='A découvrir'/>
      </div>
    )
}


