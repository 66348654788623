import { getEvent, setCurrentEvent } from "./actions/event";
import { signin, signup } from "../redux/actions/auth";
const mapDispatchToProps = (dispatch) => ({
  getEvent: () => dispatch(getEvent()),
  setCurrentEvent: id => dispatch(setCurrentEvent(id)),
  signup: (data) => dispatch(signup(data)),
  signin: (data) => dispatch(signin(data)),
});

export default mapDispatchToProps;
