import React from 'react'
import './Header.scss'
import fake_profil from '../../img/home/photo_2.png'

export default function index() {
  return (
    <header className="Header">
      <div className='h-cherriz'>
        <span>03</span><div>Cherriz</div>
      </div>
      <div className="avatar">
        <img className="img" src={fake_profil} alt="" />
        <span className="bulle">1</span>
      </div>
    </header>
  )
}
