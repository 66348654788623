import React from 'react'
import { Text } from '../../functions'
import './PinDetail.scss'


export default function PinDetail(props) {
  const {
    text = ",odjzodozpdihpzeihfpihziefhpizhfepihizf",
    icon
  } = props
  return (
    <div className="PinDetail">
      <div style={{backgroundImage: `url(${icon})`}} className="pd-icon" />
      <div className="pd-text">
        <Text>{text}</Text>
      </div>
    </div>
  )
}
