import React, { useState, useRef } from 'react'
import './Stepper.scss'
import clsx from 'clsx'

export default function Stepper(props) {
  const { className, noButtons, children, simple, finished, initialIndex = 0, addToForm, form, handlers, ...rest } = props
  const required = useRef({})
  const requiredPrev = useRef({})
  const nextButtonRules = useRef({})
  const prevButtonRules = useRef({})
  const nextButtonHandlers = useRef({})
  const [index, setIndex] = useState(initialIndex)
  const stepperRef = React.useRef(null)

  const getLength = () => {
    return Array.from(stepperRef.current.children).filter(elt => {
      return elt.tagName.toUpperCase() != 'SPAN'
    }).length
  }

  const next = () => {
    window.z = stepperRef.current
    const {current: nextHandlers} = nextButtonHandlers
    !!nextHandlers[index] && nextHandlers[index]()
    if(getLength() > index + 1) setIndex(index + 1)
  }

  const prev = () => index > 0 && setIndex(index - 1)
  const onFocus = e => {
    e.target.closest('.Stepper').scrollLeft = 0
  }

  const attr = (arr, defaut= '') => arr.map(key => {
    const [name, valType] = key.split('.')
    return { name, autoComplete: "new-password", onChange: e => {
      addToForm({...e, name}, valType)
    }, value: form[name] || defaut }
  })

  const selector = name => {
    return {
      setSelected: value => addToForm({name, value}),
      selected: form[name]
    }
  }

  const modify = (child, i) => {
    if(!child) return child
    const cls = clsx(i < index && 'step-hidden', index === i && 'step-active')
    const setRequired = (rq, newRules, isPrev) => {
      if(isPrev){
        prevButtonRules.current = {
          ...prevButtonRules.current,
          ...newRules
        }
        requiredPrev.current[i] = rq.map(key => key.split('.')[0])
      }
      else {
        nextButtonRules.current = {
          ...nextButtonRules.current,
          ...newRules
        }
        required.current[i] = rq.map(key => key.split('.')[0])
      }
    }

    const setNextHandler = handler => {
      nextButtonHandlers.current[i] = handler
    }

    const stepper = {
      form, addToForm, setRequired, attr, selector,
      prev, next, setIndex, handlers,
      setNextHandler
    }
    return React.cloneElement(child, { className: cls, stepper })
  }
  const canNext = (required.current[index] || []).reduce((acc, key) => {
    const val = form[key]
    if(!acc || !val) return false
    if(nextButtonRules.current[key]) return nextButtonRules.current[key](val)
    if(Array.isArray(val)) return val.length
    return true
  }, true)

  const canPrev = (requiredPrev.current[index] || []).reduce((acc, key) => {
    const val = form[key]
    if(!acc || !val) return false
    if(prevButtonRules.current[key]) return prevButtonRules.current[key](val)
    if(Array.isArray(val)) return val.length
    return true
  }, true)


  return (
    <div ref={stepperRef} onFocus={onFocus} {...rest} className={clsx('Stepper', className, simple && 'simple')}>
      {
        React.Children.map(children, modify)
      }
      {index > 0 && !noButtons && <span onClick={prev} className={clsx("step-control step-prev", canPrev && "active")} />}
      { !noButtons && <span onClick={next} className={clsx("step-control step-next", canNext && "active")} />}
    </div>
  )
}

export const Creator = (prp) => {
  const {stepper, children, ...props} = prp
  return <React.Fragment>{children({stepper, props})}</React.Fragment>
}
