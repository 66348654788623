import clsx from 'clsx'
import React from 'react'
import { Text } from '../../functions'
import './Ecology.scss'

export default function Ecology(props) {
  const {
    title = _title,
    detail = _detail,
    link = _link
  } = props
  return (
    <div  className={clsx("Ecology")}>
      <div className="ec-title"><Text>{title}</Text></div>
      <div className="ec-detail"><Text>{detail}</Text></div>
      {
        link && <div className='ec-link'>
          <span><Text>{link}</Text></span>
        </div>
      }
    </div>
  )
}


const _title = 'Chérie Chéri\ns’engage pour\nl’écologie !'
const _detail = 'Engages-toi avec nous, ce sera pour la planète.\nPartant(e)?'
const _link = 'En savoir plus'