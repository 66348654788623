import clsx from 'clsx'
import React from 'react'
import EventTicket from '../../components/EventTicket'
import EventCard from '../../components/EventCard'
import HomePart from '../../components/HomePart'
import InfoBack from '../../components/InfoBack'
import {icons} from '../../assets/images'
import CherieButton from '../../components/CherieButton'
import Bubble from '../../components/Bubble'
import CherieText from '../../components/CherieText'
import CherieLink from '../../components/CherieLink'
import GallerySlider from '../../components/GallerySlider'
import EventValidate from '../../components/EventValidate'
import {connect} from '../../store'
import {Redirect} from 'react-router-dom'
const texts = [
  'Invitez vos amis',
  'Vous pouvez Inviter jusqu’à 5 amis, même non membres, à participer à cet évènement !',
  'Vous voulez tout savoir sur le prochain événement ? C\'est par ici',
  'Les dernières photos Chérie Chéri',
  'Les photos des événement passés'
]
const bubbles = [
  {
    title: 'Réservez un chauffeur Bolt',
    detail: 'Et bénéficiez d’un tarif préférentiel'
  },
  {
    title: 'Réservez un chauffeur Kapten',
    detail: 'Et bénéficiez d’un tarif préférentiel'
  },
  {
    title: 'Trouvez une baby sitter',
    detail: 'Un peu de liberté pour la soirée des Cheri(e)s'
  }
]
const pluses = [
  'A short, but complete sentence that takes up first and second lines At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum. deleniti atque corrupti quos dolores et quas.',
  "molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga.",
  "A short, but complete sentence that tak At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum. dele",
  "A short, but complete sentence that takes up first and second lines At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum. dele"
]

function Event(props) {

  const [ticket, setTicket] = React.useState(false)
  const [active, setActive] = React.useState(false)
  const {event: {events, current}} = props

  const event = events.find(({id}) => {
    return id === current
  })
  if(!current) return <Redirect to={'/'}/>
  return (
    <div className={clsx("Event")}>
      <HomePart>
        <EventCard {...event}/>
      </HomePart>
     { ticket && <HomePart>
        <EventTicket
          modify={() => setActive(true)}
          text={`{event=${event.id}, eventName=${event.name}, member=7, email="user7@mail.com"}`}
          cancel = {() => setTicket(false)}
        />
      </HomePart>}
      <HomePart>
        <InfoBack
          title={texts[0]}
          detail={texts[1]}
          image={icons.doubleprofil}
        />
      </HomePart>
      <HomePart>
        <CherieButton
          simple
          text={"J'y vais"}
          onClick={() => {
            setActive(true)
          }}
        />
      </HomePart>
      <HomePart>
        {
          bubbles.map((bubble, i) => {
            return <Bubble key={i} {...bubble}/>
          })
        }
      </HomePart>
      <HomePart
        title={texts[2]}
      >
        {
          pluses.map((plus, i) => <CherieText key={i} text={plus}/>)
        }
      </HomePart>
      <HomePart
        title={texts[3]}
      >
        <GallerySlider/>
      </HomePart>
      <HomePart>
        <CherieLink text={texts[4]}/>
      </HomePart>
      <EventValidate
        event={event}
        finish={() => setActive(false)}
        active={active}
        activateTicket={() => setTicket(true)}
      />
    </div>
  )
}

export default connect(Event, ['event'])