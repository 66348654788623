import React from 'react'
import clsx from 'clsx'
import ChooserLogoBox from '../../components/ChooserLogoBox'
import {useAppContext} from '../../provider/context'

export default function StepSeven({ className, stepper, ...rest }) {
  const {setRequired, selector} = stepper
  const fields = ['cause']
  setRequired(fields)

  //Data to show
  const { cause = [] } = useAppContext().signupFields || {}
  const causesOptions = cause.filter(({is_active}) => is_active).map(({cause: label, id: value, icon_filename: image}) => ({label, value, image}))

  return (
    <fieldset {...rest} className={clsx('step stepseven', className)}>
      <div className="sgp-title spaced">Des causes?</div>
      <div className="sgp-title sub reducted">
        Choisissez la cause qui vous est chère
      </div>
      <div className="sgp-title text">
        Vous contribuez à faire un don dans la vraie vie. Merci pour elle(s)
      </div>
      <ChooserLogoBox
        options={causesOptions}
        {...selector(fields[0])}
        multiple
      />
    </fieldset>
  )
}


const causesOptions = [
  {
    image: require('../../assets/images/profil/4.png'),
    label: 'Sauvegarde des océans'
  },
  {
    image: require('../../assets/images/profil/3.png'),
    label: 'Reforestation'
  },
  {
    image: require('../../assets/images/profil/1.png'),
    label: 'Agroforesterie'
  },
  {
    image: require('../../assets/images/profil/2.png'),
    label: 'Naturellement Bio'
  },
  {
    image: require('../../assets/images/profil/3.png'),
    label: 'Theme asoss'
  },
  {
    image: require('../../assets/images/profil/5.png'),
    label: 'Ruche / Abeille'
  },
].map((obj, value) => ({ value, ...obj }))