import React from 'react'
import clsx from 'clsx'
import CherieInput from '../../components/CherieInput'

export default function StepOne({ className, stepper, ...rest }) {
  const { attr, setRequired } = stepper
  setRequired(['invisible'], {}, true)
  const fields = ['code_parain'] 
  // const fields = ['code'] 
  const F = attr(fields)
  return (
    <fieldset {...rest} className={clsx('step stepone', className)}>
      <div className="sgp-title">Mon parrain</div>
      <div className="sgp-title sub">Mon Code Parrainage</div>
      <CherieInput
        type="text" 
        placeholder="Code de Parrainage"
        {...F[0]}
      />
      <div className="sgp-minitext">
        Ce champ de saisie est dédié au code promo donné par un membre de la communauté Chérie Chéri.
      </div>
    </fieldset>
  )
}
