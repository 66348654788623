import React from 'react'
import clsx from 'clsx'
import './HomePart.scss'
import CherieLink from '../CherieLink'

export default function HomePart(props) {
  const { title, link, icon, children, simple } = props
  return (
    <div className={clsx('HomePart', simple && 'simple')}>
      {
        (title || link) && <div className='hp-title'>
          {title && <span>{title}</span>}
          {icon && <img alt='hp-icon' src={icon} />}
        </div>
      }
      {link && <CherieLink text={link} />}
      <div className="hp-content">
        {children}
      </div>
    </div>
  )
}
