import clsx from 'clsx'
import React from 'react'

import './CherieButton.scss'

export default function CherieButton(props) {
  let {
    text,
    icon,
    small,
    inverse,
    simple,
    inactive,
    children,
    ...rest
  } = props
  if(typeof children === 'string') {
    text = children
    children = ''
  }

  return (
    <div className={clsx('CherieButton', inverse && 'inverse', small && 'small', simple && 'simple', inactive && 'inactive')} {...rest}>
      {!!icon && <img src={icon} />}
      {!!text && <span>{text}</span>}
      {!!children && children}
    </div>
  )
}
