import React from 'react'
import clsx from 'clsx'
import {PhotoGrid} from '../../components/PhotoBox'
import Plaque from '../../components/Plaque'
import {icons} from '../../assets/images'

export default function StepEight({ className, stepper, ...rest }) {
  const {attr, setRequired} = stepper
  const fields = ['photo.images']
  const F = attr(fields)
  setRequired(fields, {
    'images': imgs => {
      return imgs.length === 3
    }
  })
  return (
    <fieldset {...rest} className={clsx('step stepeight', className)}>
      <div className="sgp-title spaced">Cheessse!!</div>
      <div className="sgp-title sub">
        Une photo de profil c'est mieux pour être un ou une parfait(e) Cheri(e)
      </div>
      <PhotoGrid {...F[0]}/>
      <Plaque
        image = {icons.starsmile}
        text = {`Positionne toi bien devant l’objectif et pas trop près bien sûr.\n`+
          `Choisis un fonduni c'est idéal et n'oublies pas de t'orienter vers une belle lumière :) Voila le tour est joué!`
        }
      />
    </fieldset>
  )
}
