export const URL = {
  auth_route: {
    signup_fb: "/api/auth/signup_fb",
    signup_gmail: "/api/auth/signup_gmail",
    signup_apple: "/api/auth/signup_apple",
    signup: "/api/auth/signup",
    valide_info_user: "/api/auth/valide_info_user",
    signin: "/api/auth/signin",
    get_profil_account: "/api/auth/get_complete_profil",
    test_many: "/api/auth/get_complete_profil/test_many",
    test_add_project: "/api/auth/get_complete_profil/test_add_project",
    saveUserInfo: "/api/auth/valide_info_user",
  },
  accueil: {
    get_all_event: "/api/event/get_event",
  },
};
