import * as types from "../../../constant/redux.constant";

const initialState = {
  events: [],
  current: null

}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ALL_EVENT:
      return {
        ...state,
        events: action.data,
      };
    case types.SET_CURRENT_EVENT:
      return {
        ...state,
        current: action.data,
      };
    default:
      return state;
  }
};
