export const titles = [
  'Merveilleuse Soirée chez Apicius'
]
export const details = [
  'A short, but complete sentence that takes up first and second line\nAt vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis',
  ' praesentium voluptatum.\n deleniti atque corrupti quos dolores et quas.',
]
export const dates = [
  "Samedi 25 séptembre 2020"
]

export const adresses = [
  `La péniche Chérie Cheri\n14 Rue Monsieur le Prince\n75006 Paris`
]

export const getRandom = arr => arr[Math.floor(Math.random() * arr.length)]