export const formatDate = (date = new Date()) => {
  if(typeof date == 'string') {
    date = date.split('-').map(c => parseInt(c))
    date = new Date(date[0], date[1] - 1, date[2])
  }
  const lang = 'fr'
  
  var days = {
    fr: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi','Jeudi', 'Vendredi', 'Samedi'],
  };
  var format = {
    fr: ({dayName, day, monthName, year}) =>
      `${dayName}, ${day} ${monthName} ${new Date().getFullYear() == year ? '' : year}`
  }
  
  return format[lang]({
    day: date.getDate(),
    month: date.getMonth(),
    year: date.getFullYear(),
    dayName: days[lang][date.getDay()],
    monthName: formatDate.monthNames[lang][date.getMonth()]
  });
}
formatDate.monthNames = {
  fr: ['Janvier', 'Février', 'Mars','Avril', 'Mai', 'Juin', 'Juillet','Août', 'Septembre', 'Octobre','Novembre', 'Décembre'],
  frc: ['Jan', 'Fév', 'Mar','Avr', 'Mai', 'Jun', 'Jui','Aoû', 'Sep', 'Oct','Nov', 'Déc'],
};

export const addZero = (nb, min = 2) => {
  nb = nb + ''
  return nb.padStart(min, '0')
}