import clsx from 'clsx'
import React from 'react'
import './People.scss'

export default function People(props) {
  const {className, ...rest} = props
  return (
    <div {...rest} className={clsx('People', className)}>
      <div className="images">
        <div className="image"></div>
        <div className="image"></div>
        <div className="image"></div>
        <div className="image"></div>
        <div className="image"></div>
        <div className="image"></div>
      </div>
      <span>Voir les 38 participants</span>
      <span>&nbsp;&gt;</span>
    </div>
  )
}
