import clsx from 'clsx'
import React from 'react'
import './Bubble.scss'

export default function Bubble(props) {
  const {
    title = "njblfbzlebf",
    detail = 'nifhzifize',
    image
  } = props
  return (
    <div className={clsx('Bubble')}>
      <div className="b-logo" />
      <div className="b-texts">
        <div className="b-title">{title}</div>
        <div className="b-detail">{detail}</div>
      </div>
    </div>
  )
}
