import React from 'react'
import clsx from 'clsx'
import CherieInput from '../../components/CherieInput'

export default function StepTwo({ className, stepper, ...rest }) {
  const {setRequired, attr} = stepper
  const fields = ['firstname', 'lastname', 'adress', 'date_of_birth']
  setRequired(fields)
  const F = attr(fields)
  return (
    <fieldset {...rest} className={clsx('step steptwo', className)}>
      <div className="sgp-title">Mon identité</div>
      <CherieInput
        type="text" 
        placeholder="Mon prénom"
        {...F[0]}
      />
      <CherieInput
        type="text" 
        placeholder="Mon nom"
        {...F[1]}
      />
      <CherieInput
        type="text" 
        placeholder="Mon lieu de Résidence"
        {...F[2]}
      />
      <div className="sgp-title sub spaced">Ma Date d'anniversaire*</div>
      <CherieInput
        type="date"
        {...F[3]}
      />
    </fieldset>
  )
}
