import React from 'react';
import Footer from './components/Footer'
import Header from './components/Header'
import {connect} from './store'
import Routes, { getFromRoute } from './Router'
import './App.scss'
import clsx from 'clsx'
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom'
import { AppContext } from './provider/context'

function App(props) {
  const {token} = props.auth
  React.useEffect(() => {
    //check Token
  }, [])
  return (
    <AppContext.Provider
      value={{}}
    >
      <Router>
        <Route
          render={
            ({ location: { pathname } }) => {
              const { header, footer, isProtected, path } = getFromRoute(pathname)
              if(path === undefined) return <Redirect to={'/'}/>
              else if(isProtected && !token) return <Redirect to={'/signup'}/>
              else return (
                <div className="App">
                  {header && <Header />}
                  <main className={clsx(header && 'with-header', footer && 'with-footer')}>
                    <Routes />
                  </main>
                  {footer && <Footer />}
                </div>
              )
            }
          }
        />
      </Router>
    </AppContext.Provider>
  )
}


export default connect(App, ['auth'])