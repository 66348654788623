import clsx from 'clsx'
import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import Cherie from '../Cherie'
import 'swiper/swiper.scss'
import SwiperCore, { Mousewheel } from 'swiper'
SwiperCore.use([Mousewheel]);

export default function CherieSlider(props) {
  const { cheries = ['Abby',
  'Lacey',
  'Lucia',
  'Felix',
  'Anisa',
  'Jessie',
  'Frankie (Fannie)',
  'Amber',
  'Lisa',
  'Sylvia',
  'Isabella (Isie)',
  'Sophie (Sofi)'] } = props
  return (
    <div className={clsx('CherieSlider')}>
      <Swiper
        direction="horizontal"
        slidesPerView="auto"
        spaceBetween={15}
        mousewheel={{
          forceToAxis: true
        }}
        freeMode={true}
        onSlideChange={() => 0}
        onSwiper={(swiper) => setTimeout(() => {
          swiper.update()
        }, 200)}
        style={{ padding: 3 }}
        slidesOffsetAfter={25}
      >
        {
          cheries.map((cherie, key) => {
            return <SwiperSlide key={key} style={{ width: "auto" }}>
              <Cherie  index={key} name={cherie} />
            </SwiperSlide>
          })
        }
      </Swiper>
    </div>
  )
}
