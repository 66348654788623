import clsx from 'clsx'
import React from 'react'
import { titles, details } from '../../functions/template'
import './InfoBack.scss'
import def from '../../assets/images/home/cadeau.svg'


export default function InfoBack(props) {
  const {
    title = titles[0],
    detail = details[0],
    image = def,
    side
  } = props
  
  return (
    <div className={clsx("InfoBack")}>
      {
        side && <div style={{backgroundImage: `url(${image})`}} className="ib-back"/>
      }
      <div className="ib-texts">
        <div className="ib-title">{title}</div>
        <div  className="ib-detail-parent">
          {!side && <div style={{backgroundImage: `url(${image})`}} className="ib-back"/>}
          <div className="ib-detail">{detail}</div>
        </div>
      </div>
    </div>
  )
}
