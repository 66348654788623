import clsx from 'clsx'
import React from 'react'
import { icons } from '../../../assets/images'
import { details, getRandom } from '../../../functions/template'
import './CharteField.scss'

const { heartok, heartcare, shieldok } = icons
export default function Charte(props) {
  const {className, stepper, ...rest} =  props
  const {setRequired} = stepper
  setRequired(['finished'])
  const {setNextHandler, handlers} = stepper
  setNextHandler(handlers.navigate)
  return (
    <div className={clsx("Charte", className)} {...rest}>
      <div className="sgp-title spaced">Notre charte de confiance</div>
      {
        ['Confiance', 'Sécurité', 'Respect', 'Respect'].map((title, i) => (
          <CharteField title={title} key={i}/>
        ))
      }
    </div>
  )
}


export const CharteField = (props) => {
  const {title} = props
  return (
    <div className='CharteField'>
      <img src={getRandom([heartcare, shieldok, heartok])} alt="logo" />
      <div className="cf-texts">
        <div className="cf-title">{title}</div>
        <div className="cf-detail">{getRandom(details)}</div>
      </div>
    </div>
  )
}
