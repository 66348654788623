import { getEvents } from "../../../backend/EventbackEndService";
import { GET_ALL_EVENT, SET_CURRENT_EVENT } from "../../../constant/redux.constant";

/**
 * Create new user
 * @param {*} email
 * @param {*} callback
 */

export const getEvent = () => async (dispatch) => {
  const result = await getEvents()
  dispatch({ type: GET_ALL_EVENT, data: result })
}

export const setCurrentEvent = id => async dispatch => {
  dispatch({ type: SET_CURRENT_EVENT, data: id })
}
