import React from 'react'
import './LogoBox.scss'
import clsx from 'clsx'

export default function LogoBox(props) {
  const {label, className, image, setChecked, checked, ...rest} = props
  return (
    <div onClick={_ => setChecked(!checked)} className={clsx("LogoBox", checked && 'checked')} {...rest}>
      <div style={{backgroundImage: `url(${image.default || image})`}} className='logo'/>
      <div className="label">{label}</div>
    </div>
  )
}
