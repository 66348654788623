import * as UserService from "../../../backend/UserbackEndService";
import { SIGNIN } from "../../../constant/redux.constant";

export const signin = data => async dispatch => {
  const result = await UserService.signin(data)
  dispatch({ type: SIGNIN, data: result })
  return result
};

export const signup = data => async dispatch => {
  const result = await UserService.signup(data)
  console.log(result)
  dispatch({ type: SIGNIN, data: result })
  return result
};
