import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Mousewheel } from 'swiper'
import GalleryPart from './GalleryPart'
import 'swiper/swiper.scss'
import clsx from "clsx"
SwiperCore.use([Mousewheel]);
const im = [1,1,1,1,1,1,1,1,1,1,1,1]

export default function GallerySlider(props) {
  const {images=im} = props
  const [galleries, setGalleries] = React.useState([])
  React.useEffect(() => {
    var R = [];
    for (var i = 0; i < images.length; i += 5) R.push(images.slice(i, i + 5));
    setGalleries(R)
  }, [images])
  
  return (
    <div className={clsx('GallerySlider')}>
      <Swiper
          direction= 'horizontal'
          freeMode={true}
          slidesPerView="auto"
          mousewheel={{
            forceToAxis: true
          }}
          onSwiper={(swiper) => setTimeout(() => {
            swiper.update()
          }, 200)}
          style={{padding: 3}}
          // slidesOffsetAfter={25}
      >
        {
          galleries.map((gallery, key) => {
            return <SwiperSlide key={key} style={{width: "auto"}}>
              <GalleryPart index={key} images={gallery}/>
            </SwiperSlide>
          })
        }
      </Swiper>
    </div>
  )
}