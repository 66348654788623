import React from 'react'
import home from '../../assets/images/footer/home.svg'
import friends from '../../assets/images/footer/friends.svg'
import chat from '../../assets/images/footer/chat.svg'
import calendar from '../../assets/images/footer/calendar.svg'
import yinyang from '../../assets/images/footer/yinyang.svg'
import './Footer.scss'

export default function Footer(props) {
  const { active = 'home' } = props

  return (
    <footer className="Footer" style={{zIndex: 1000}}>
      <span className={`pic ${active === 'home' && 'active'}`}>
        <img src={home} alt='home'/>
      </span>
      <span className={`pic ${active === 'friends' && 'active'}`}>
        <img src={friends} alt='friends'/>
        <p className="bulle">1</p>
      </span>
      <span className={`pic ${active === 'chat' && 'active'}`}>
        <img src={chat} alt='chat'/>
        {/* <p className="bulle">99</p> */}
      </span>
      <span className={`pic ${active === 'calendar' && 'active'}`}>
        <img src={calendar} alt='calendar'/>
      </span>
      <span className={`pic ${active === 'yinyang' && 'active'}`}>
        <img src={yinyang} alt='yinyang'/>
      </span>
    </footer>
  )
}
