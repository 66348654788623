import React from 'react'
import clsx from 'clsx'
import './Chooser.scss'

export default function Chooser(props) {
  const { full, big, multiple, spaced, options = [], selected = [], setSelected, ...rest} = props
  const addSelected = val => {
    if (selected.includes(val)) {
      setSelected && setSelected(selected.filter(v => v !== val))
    }
    else {
      setSelected && setSelected(multiple ? [...selected, val] : [val])
    }
  }
  return (
    <div className={clsx("Chooser", full && 'full', big && 'big')} {...rest}>
      {
        options.map(({ value, label }, i) => {
          return (
            <div
              key={i}
              className={clsx('choice', selected.includes(value) && 'active', spaced && 'spaced')}
              onClick={e => addSelected(value)}
            >
              {label}
            </div>
          )
        })
      }
    </div>
  )
}
