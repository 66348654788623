import clsx from 'clsx'
import React from 'react'
import './CurtailOne.scss'
import { icons } from '../../../assets/images'
import CherieButton from '../../../components/CherieButton'

const items = [
  {
    detail: 'Une occasion de participer à la nouvelle expérience digitale de la communauté.',
    icon: icons.glass
  },
  {
    icon: icons.gift,
    detail: "En complétant votre profil vous deviendrez un utilisateur VIP et gagnerez des crédits qui vous serviront dans la future application."
  }
]

export default function CurtailOne(props) {
  const {className, stepper, ...rest} =  props
  const {next, setRequired} = stepper
  setRequired(['invisible'], {})
  return (
    <div {...rest} className={clsx('CurtailOne', className)}>
      <img src={icons.infinite} alt="infinite" className="co-logo" />
      <div className="co-title">Bienvenue chez les Chéri(e)s</div>
      {
        items.map(({ detail, icon }, i) => {
          return (
            <div key={i} className="co-item">
              <img src={icon} alt="glasses" className="co-item-logo" />
              <div className="co-item-detail">{detail}</div>
            </div>
          )
        })
      }
      <div className="button-parent">
        <CherieButton onClick={next} style={{background: '#fff'}} inverse>Je complète mon profil</CherieButton>
      </div>
    </div>
  )
}
