import clsx from 'clsx'
import React, { useState } from 'react'
import PinDetail from '../PinDetail'
import './EventValidate.scss'
import { icons } from '../../assets/images'
import BigCheckbox from '../BigCheckbox'
import Button from '../CherieButton'
import Chooser from '../Chooser'
import Stepper, { Creator } from '../Stepper'
import { addZero } from 'common/functions'

const generateArray = (nb = 0) => {
  const tab = []
  while (nb > 0) {
    tab.push(nb >= 2 ? 2 : 1)
    nb -= 2
  }
  return tab
}

export default function EventValidate(props) {
  const { active, finish } = props
  const [form, setForm] = useState({})
  const guestArray = generateArray(form.nb)
  const fields = ["nb", "hour", 'table', 'guests']
  const addToForm = (e, valType = 'value') => {
    const source = e.target || e
    form[source.name] = source[valType]
    setForm({
      ...form,
      [source.name]: source[valType]
    })
  }
  return (
    <div className={clsx('EventValidate', active && 'active')}>
      <div className="ev-handler" />
      <div className="ev-title" style={{ marginBottom: 0 }}>
        {`J'assisterai à l'évènement`}
      </div>
      <div className="ev-more">
        <PinDetail
          icon={icons.pin}
          text={"La péniche Chérie Cheri\n14 Rue Monsieur le Prince\n75006 Paris"}
        />
        <PinDetail
          icon={icons.clock}
          text={"A partir de\n19h30"}
        />
      </div>
      <Stepper handlers={{}} {...{ addToForm, form }} initialIndex={0} noButtons simple style={{ height: 370 }}>
        <Creator>
          {
            ({ stepper: { next, attr, form, addToForm, selector, setIndex }, props }) => {
              const F = attr(fields)
              const sendData = () => {
                setIndex(0)
                finish()
                // send()
              }
              const changeNb = toAdd => {
                let value = (parseInt(form[fields[0]]) || 0) + toAdd
                if (value < 0) value = 0
                else if (value > 5) value = 5
                addToForm({
                  name: fields[0],
                  value: value + ''
                })
              }
              return (
                <div {...props}>
                  <div className="ev-option">
                    <div className="ev-opt-title">
                      <div className="ev-label">Inviter des amis à l’Événement</div>
                      <div className="ev-rest">
                        <span>Il reste : </span>
                        <span className="ev-data">120 places</span>
                      </div>
                    </div>
                    <input readOnly {...F[0]} className="ev-friend-count" type="number" />
                    <div className="ev-increase">
                      <span onClick={() => changeNb(-1)} className="moin">-</span>
                      <span onClick={() => changeNb(1)} className="plus">+</span>
                    </div>
                  </div>
                  <div className="separator" />
                  <div className="ev-option">
                    <div className="ev-opt-title">
                      <div className="ev-label">Reservez une table</div>
                      <div className="ev-rest">
                        <span>Il reste : </span>
                        <span className="ev-data">10 tables</span>
                      </div>
                    </div>
                    <BigCheckbox {...F[2]} simple />
                  </div>
                  <div className="separator" />
                  <div className="ev-option">
                    <div className="ev-label">Choisir la plage horraire</div>
                  </div>
                  <Chooser
                    big
                    {...selector(fields[1])}
                    options={_creneau}
                    style={{ marginTop: "1em" }}
                  />
                  <Button inactive={!form[fields[1]] || !form[fields[0]]} onClick={() => {
                    addToForm({
                      name: fields[3],
                      value: (form[fields[3]] || []).slice(0, form[fields[0]] || 0)
                    })
                    parseInt(form[fields[0]]) ? next() : sendData()
                  }} text='Je valide' />
                  <Button style={{ margin: 'auto', marginTop: 5, width: 200 }} inverse small onClick={finish} text='Annuler' />
                </div>
              )
            }
          }
        </Creator>
        {
          guestArray.map((nb, i) => (
            <Creator key={i}>
              {
                ({ stepper: { next, prev, addToForm, setIndex }, props }) => {
                  const isLast = i == guestArray.length - 1
                  const sendData = () => {
                    setIndex(0)
                    finish()
                    // send()
                  }
                  const verifyFields = term => {
                    return !!term && !!term['email'] && !!term['name']
                  }
                  let canNext = !!form[fields[3]] && verifyFields(form[fields[3]][i * 2])
                  if (nb == 2) canNext = canNext && verifyFields(form[fields[3]][i * 2 + 1])
                  const setData = (position, field, value) => {
                    const guests = form[fields[3]] || []
                    guests[i * 2 + position] = guests[i * 2 + position] || {}
                    guests[i * 2 + position][field ? 'email' : 'name'] = value
                    addToForm({
                      name: fields[3],
                      value: guests
                    })
                  }

                  return (
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }} {...props}>
                      <div className="ev-option column">
                        <div className="ev-label">{`Invité ${addZero(i * 2 + 1)}`}</div>
                        <input onChange={e => setData(0, 0, e.target.value)} placeholder="Prénom" type="text" />
                        <input onChange={e => setData(0, 1, e.target.value)} placeholder="Email" type="email" />
                      </div>
                      {
                        nb == 2 && <div style={{ pointerEvent: 'none' }} className="ev-option column">
                          <div className="ev-label">{`Invité ${addZero(i * 2 + 2)}`}</div>
                          <input onChange={e => setData(1, 0, e.target.value)} placeholder="Prénom" type="text" />
                          <input onChange={e => setData(1, 1, e.target.value)} placeholder="Email" type="email" />
                        </div>
                      }
                      <div className="ev-last-options">
                        <Button onClick={prev} simple inverse text='Retour' />
                        <Button
                          onClick={isLast ? sendData : next}
                          simple
                          text={isLast ? 'Je confirme' : 'Suivant'}
                          inactive={!canNext}
                        />
                      </div>
                    </div>
                  )
                }
              }
            </Creator>
          ))
        }
      </Stepper>
    </div>
  )
}

const _creneau = [
  { value: 1, label: '19h 30' },
  { value: 2, label: '21h 00' },
  { value: 3, label: '22h 30' },
]
