import clsx from 'clsx'
import React from 'react'
import './CherieInput.scss'

export default function CherieInput(props) {
  const {...rest} = props
  return (
    <div className={clsx('CherieInput')}>
      <input {...rest}/>
    </div>
  )
}
